.wrapper--half {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  background-color: var(--white);

  .cta__title {
    margin-bottom: 1.25rem;
  }
}

.cta__left {
  background-color: var(--white);
}

/* .cta__right {
} */

.cta__right--blue {
  background-color: var(--blue);
}

.cta__right--orange {
  background-color: var(--orange);
}

.cta__image,
.contact__image {
  margin-left: auto;
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.cta__content {
  height: 100%;
  color: var(--fg);
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.cta__content--padding {
  padding-top: 4rem;
  padding-bottom: 4rem;

  @media (--media-sm-only) {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}

.cta__title {
  color: var(--white);
  margin-bottom: 2.5rem;
  text-align: center;
}

.cta__title + .cta__button {
  margin-bottom: 2.5rem;
}

.cta__text + .cta__button {
  margin-top: 3.5rem;
}

.contact {
  --_width: auto;
  padding-bottom: 0;

  @media (--media-md) {
    --_width: 39rem;
    padding-top: 6rem;
  }

  @media (--media-lg) {
    padding-bottom: 7.5rem;
  }
}

.contact__image {
  max-width: var(--_width);

  @media (--media-mobile) {
    margin-right: auto;
  }
}

.contact__right {
  display: flex;
  align-items: center;
}

.contact__content {
  padding-block: 4.38rem;
  width: var(--_width);
  max-width: 100%;
  margin: 0 auto;

  @media (--media-lg) {
    padding-left: 4.38rem;
    margin-left: 0;
  }

  @media (--media-sm-only) {
    padding-inline: 1rem;
  }
}

.contact__title {
  margin-bottom: 2rem;
}

.contact__name {
  padding-top: 3.5rem;

  @media (--media-sm-only) {
    padding-top: 2.5rem;
  }
}

.cta__button {
  --bg: var(--white);
}

@media (--media-lg) {
  .wrapper--half {
    grid-template-columns: 1fr 1fr;
  }

  .cta__image {
    width: 50rem;
  }
}
